// return June 25, 2015
function getLocalDate(unix_timestamp) {
    var date = new Date(unix_timestamp*1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = date.getFullYear();
    var month = months[date.getMonth()];
    var date = date.getDate();
    return month + ' ' + date + ', ' + year;
};
doNotCloseCollectionBox = true;
isLoadingCollectionNames = false;
function closeCollectionBox($collectionBox) {
    if ($collectionBox == null) {
        if (window.location != window.parent.location) {
            $collectionBox =  window.parent.$('#collections-box');
        } else {
            $collectionBox =  $('#collections-box');
        }
    }
    $collectionBox.hide();
    doNotCloseCollectionBox = true;
}
function showCollectionBox($button, callback) {
    var $collectionBox;
    if (window.location != window.parent.location) {
        $collectionBox = $('#collections-box');
        if ($collectionBox.length <= 0) {
            $collectionBox = window.parent.$('#collections-box').clone();
            $collectionBox.appendTo($('.photo-detail-container'));
        }
        $collectionBox.css({
            position: 'absolute',
            top: 50,
            left: 10
        });
    } else {
        $collectionBox =  $('#collections-box');
        var left = $button.offset().left;
        var css = {
            position: 'absolute',
            top: $button.offset().top + 30
        };
        if (left + $collectionBox.outerWidth() > $('body').width()) {
            css['left'] = left - $collectionBox.outerWidth() + 30;
        } else {
            css['left'] = left;
        }
        $collectionBox.css(css).show(function() {
            doNotCloseCollectionBox = false;
        });
    }
    $collectionBox.show(function() {
        doNotCloseCollectionBox = false;
    });
    var nowCollections = [];
    var $parent = $button.parents('.card');
    if ($parent.length <= 0) {
        $parent = $button.parents('#detailContainer');
    }
    nowCollections = $parent.data('collections').toString().split(',');
    var id = $button.data('id');
    $collectionBox.find('#collection_post_id').val(id);
    if (isLoadingCollectionNames == false) {
        isLoadingCollectionNames = true;
        $.get('/collection/get/names', function(data) {
            $collectionBox.find('#collection-loading-names').fadeOut(400, function() {
                $(this).remove();
                $.each(data, function(id, name) {
                    var $row = $collectionBox.find('#collections-sample').clone().show();
                    $row.find('.collections-name').text(name).removeAttr('id');
                    $row.find('.checkCollection').prop('checked', (nowCollections.indexOf(id) >= 0)).val(id);
                    $collectionBox.append($row);
                });
            });
        });
    } else {
        $collectionBox.find('.checkCollection').each(function() {
            $(this).prop('disabled', true);
            var key = $(this).val();
            $(this).prop('disabled', false).prop('checked', (nowCollections.indexOf(key) >= 0));
        });
    }
    $collectionBox.find('.checkFavorite').val($button.data('id'));
    if (typeof(callback) == 'function') {
        callback($collectionBox);
    }
}

function favoritePost($favItem) {
    $.post('/favorite/do', {postId: $favItem.data('id')}, function(resp) {
        if (resp == true) {
            var amountItem = $favItem.addClass('favorited').find('.favorite-amount');
            if (amountItem.length > 0) {
                amountItem.text(parseInt(amountItem.text()) + 1);
            }
            if ($favItem.not('.favorite-inverse')) {
                $favItem.find('.fa').removeClass('fa-star-o').addClass('fa-star');
            }
            $favItem.tooltip({title: 'You favorite this'}).tooltip('show');
            setTimeout(function() {
                $favItem.tooltip('destroy');
            }, 2000);
        }
    });
}
function unFavoritePost($favItem) {
    $.post('/favorite/delete', {postId: $favItem.data('id')}, function(resp) {
        if (resp == true) {
            var amountItem = $favItem.removeClass('favorited').find('.favorite-amount');
            if (amountItem.length > 0) {
                amountItem.text(parseInt(amountItem.text()) - 1);
            }
            if ($favItem.not('.like-inverse')) {
                $favItem.find('.fa').addClass('fa-star-o').removeClass('fa-star');
            }
        }
    });
}
$(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $('#showNavButton').click(function(e) {
        e.preventDefault();
        var nav = $('#navMenu');
        if (nav.is(':hidden')) {
            nav.slideDown();
        } else {
            nav.slideUp();
        }
    });
    var collectionAjaxDoing = false;
    $('body').on('click', '.like:not(.liked, .like-disabled)', function(e) {
        e.preventDefault();
        var $likeItem = $(this);
        $.post('/like/do', {postId: $(this).data('id')}, function(resp) {
            if (resp == true) {
                var amountItem = $likeItem.addClass('liked').find('.like-amount');
                if (amountItem.length > 0) {
                    amountItem.text(parseInt(amountItem.text()) + 1);
                }
                if ($likeItem.not('.like-inverse')) {
                    $likeItem.find('.fa').removeClass('fa-heart-o').addClass('fa-heart');
                }
                $likeItem.tooltip({title: 'You like this'}).tooltip('show');
                setTimeout(function() {
                    $likeItem.tooltip('destroy');
                }, 2000);
            }
        });
    }).on('click', '.liked', function(e) {
        e.preventDefault();
        var $likeItem = $(this);
        $.post('/like/delete', {postId: $(this).data('id')}, function(resp) {
            if (resp == true) {
                var amountItem = $likeItem.removeClass('liked').find('.like-amount');
                if (amountItem.length > 0) {
                    amountItem.text(parseInt(amountItem.text()) - 1);
                }
                if ($likeItem.not('.like-inverse')) {
                    $likeItem.find('.fa').addClass('fa-heart-o').removeClass('fa-heart');
                }
            }
        });
    }).on('click', '.favorite:not(.favorited, .favorite-disabled)', function(e) {
        e.preventDefault();
        var $likeItem = $(this);
        showCollectionBox($likeItem, function($box) {
            $box.find('.checkFavorite').prop('checked', true);
        });
        favoritePost($likeItem);
    }).on('click', '.favorited', function(e) {
        e.preventDefault();
        var $likeItem = $(this);
        showCollectionBox($likeItem, function($box) {
            $box.find('.checkFavorite').prop('checked', true);
        });
    }).on('click', '.checkFavorite', function(event) {
        var $favItem;
        if (window.location != window.parent.location) {
            $favItem = window.$('#favorite-' + $(this).val());
        } else {
            $favItem = $('#favorite-' + $(this).val());
        }
        if ($(this).prop('checked') == true) {
            favoritePost($favItem);
        } else {
            unFavoritePost($favItem);
        }
    }).on('click', '#add-new-collections', function(event) {
        event.preventDefault();
        var $name = $('#new-collections-name');
        var name = $name.val();
        var $parent = $(this).parents('#collections-box');
        if (name != '' &&  ! collectionAjaxDoing) {
            $parent.find('input').prop('disabled', true);
            collectionAjaxDoing = true;
            $.ajax({
                url: '/collection/create/name',
                type: 'POST',
                dataType: 'json',
                data: {name: name},
            }).done(function(resp) {
                if (resp.success == false) {
                    $row = $('<li class="alert-danger">' + resp.error + '</li>');
                    $row.appendTo($parent).delay(3000).fadeOut(300, function() {
                        $(this).remove();
                    });
                } else {
                    $name.val('');
                    var $sample = $parent.find('#collections-sample');
                    var $row = $sample.clone().show();
                    $row.find('.collections-name').text(name).removeAttr('id');
                    $row.find('.checkCollection').val(resp.id).prop('checked', false);
                    $parent.append($row);
                }
            }).fail(function(resp) {
                $row = $('<li class="alert-danger">ERROR. Please try again.</li>');
                $row.appendTo($parent).delay(3000).fadeOut(300, function() {
                    $(this).remove();
                });
            }).always(function() {
                collectionAjaxDoing = false;
                $parent.find('input').prop('disabled', false);
            });
        }
    }).on('click', '.follow', function(e) {
        e.preventDefault();
        var $followItem = $(this);
        $.ajax({
            url: '/follow/followSomeone',
            type: "POST",
            headers: { 
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {followId: $(this).data('id')},
            success: function(resp) {
                if (resp == true) {
                    $followItem.removeClass('follow').addClass('unfollow')
                        .find('i').addClass('fa-check').removeClass('fa-plus').removeClass('fa-times')
                        .next('span').text('Following');
                }
            }
        });
    }).on('mouseenter', '.unfollow', function(e) {
        $(this).find('i').addClass('fa-times').removeClass('fa-check').next('span').text('Unfollow');
    }).on('mouseleave', '.unfollow', function(e) {
        $(this).find('i').addClass('fa-check').removeClass('fa-times').next('span').text('Following');
    }).on('click', '.unfollow', function(e) {
        e.preventDefault();
        var $followItem = $(this);
        $.post('/follow/unFollowSomeone', {followId: $(this).data('id')}, function(resp) {
            if (resp == true) {
                $followItem.addClass('follow').removeClass('unfollow')
                    .find('i').addClass('fa-plus').removeClass('fa-check').removeClass('fa-times')
                    .next('span').text('Follow');
            }
        });
    }).on('click', '.checkCollection', function(event) {
        event.preventDefault();
        var $parent = $(this).parents('li');
        var $checkButton = $(this);
        var postId = $(this).parents('#collections-box').find('#collection_post_id').val();
        var id = $checkButton.val();
        if ($checkButton.prop('checked') == true) {
            $.post('/collection/create', {
                'post_id': postId,
                'id': id
            }, function(data, textStatus, xhr) {
                if (data.success == true) {
                    $checkButton.prop('checked', true);
                    var $postItem = $('#card-' + postId);
                    if ($postItem.length <= 0) {
                        $postItem = $('#detailContainer');
                    }
                    var collections = $postItem.data('collections');
                    $('#card-' + postId + ',#detailContainer').data('collections', collections + ',' + id);
                    $parent.addClass('collection-active').removeClass('collection-inactive');
                }
            });
        } else {
            $.post('/collection/delete', {
                'post_id': postId,
                'id': id
            }, function(data, textStatus, xhr) {
                if (data.success == true) {
                    $checkButton.prop('checked', false);
                    $parent.addClass('collection-inactive').removeClass('collection-active');
                    var $postItem = $('#card-' + postId);
                    if ($postItem.length <= 0) {
                        $postItem = $('#detailContainer');
                    }
                    var collections = $postItem.data('collections').toString().split(',');
                    var i = collections.indexOf(id);
                    if (i != -1) {
                        collections.splice(i, 1);
                    }
                    $('#card-' + postId + ',#detailContainer').data('collections', collections.join(','));
                }
            });
        }
    });
    $('#deleteCollectionModal').on('show.bs.modal', function(e) {
        $(this).find('#removeCollectionConfirmButton').data('id', $(e.relatedTarget).data('id'));
    }).on('click', '#removeCollectionConfirmButton', function(e) {
        e.preventDefault();
        var id = $(this).data('id');
        $.post('/collection/delete/name', {
            id: id
        }, function(data, textStatus, xhr) {
            if (data.success == true) {
                $modalDiv.modal('hide').removeClass('loading');
                $('#collection-box-' + id).fadeOut('200', function() {
                    $(this).remove();
                });
            }
        });
        var $modalDiv = $(e.delegateTarget);
        $modalDiv.addClass('loading');
    });
    $('.time').each(function(index, el) {
        $(this).text(getLocalDate($(this).data('timestamp')));
    });
    $('body').on('click', '#photoContainer .image-post-card .mdl-card__title', function(event) {
        location.href = $(this).parent().data('href');
    });
    $('body').on('click', '.colorbox-next-photo-btn', function(event) {
        event.preventDefault();
        $.colorbox.prev();
    });
    $('body').on('click', '.colorbox-prev-photo-btn', function(event) {
        event.preventDefault();
        $.colorbox.next();
    });

    $('html').keydown(function(e){
        if ( ! $(e.target).is('.comment-textarea')) {
            if (e.keyCode == 39) {
                $.colorbox.next();
            } else if (e.keyCode == 37) {
                $.colorbox.prev();
            }
        }
    });
    
    $('[data-toggle="tooltip"]').tooltip();

    var startGetNotifies = false;
    $('#notifyDropdown').click(function(event) {
        event.preventDefault();
        if ( ! startGetNotifies) {
            startGetNotifies = true;
            $.get('/notify/getRecentList/10/0/1', {}, function(data, textStatus, xhr) {
                if (data) {
                    $('#notifyList').html(data);
                    $('#notifyDropdown').find('.badge-unread').remove();
                }
            });
        }
    });
    var stopWindowScroll = false, previousScrollTop = 0, windowHeight = $(window).height(), $body = $("html, body");
    var $topButton = $('#topButton');
    $('#notifyList').mouseenter(function(event) {
        stopWindowScroll = true;
    }).mouseleave(function(event) {
        stopWindowScroll = false;
    });
    $(window).scroll(function(event) {
        if (stopWindowScroll) {
            $(window).scrollTop(previousScrollTop); 
        }
        previousScrollTop = $(window).scrollTop();

        if ($topButton.length > 0) {
            if (previousScrollTop > windowHeight) {
                $topButton.fadeIn();
            } else {
                $topButton.fadeOut();
            }
        }
    });
    $topButton.click(function(event) {
        event.preventDefault();
        $body.stop().animate({scrollTop:0}, '500', 'swing', function() { 
        });
    });

    // author box
    $('body').on('mouseleave', '.artist-box', function(event) {
        var $box = $(this);
        $box.fadeOut().data('enter', 0);
    }).on('mouseenter', '.artist-box', function(event) {
        $(this).data('enter', 1);
    });
    $('.show-artist-box').mouseleave(function(event) {
        var username = $(this).data('username'),
            $box = $body.find('#artistBox-' + username);
        setTimeout(function(){
            if ($box.data('enter') != 1) {
                $box.fadeOut();
            }
        }, 500);
    });
    $('.show-artist-box').mouseenter(function(event) {
        var username = $(this).data('username'),
            $box = $body.find('#artistBox-' + username);
        if ($box.length < 1) {
            $box = $('<div class="artist-box" id="artistBox-' + username + '" style="display: none;"><div class="arrow"></div></div>').appendTo('body');
            var originHeight = $box.height();
            $.get('/artist/' + username + '/box', function(data) {
                if (data != '') {
                    $box.append(data);
                    $box.css('top', $box.position().top - ($box.height() - originHeight));
                }
            });
        }
        var offset = $(this).offset();
        $box.css({
            position: 'absolute',
            top: offset.top - $box.outerHeight(true) - 10,
            left: offset.left - ($box.outerWidth(true) / 2) + ($(this).outerWidth() / 2)
        }).fadeIn();
    });
    // collections
    $('#collections-box').mouseenter(function(event) {
        doNotCloseCollectionBox = true;
    }).mouseleave(function(event) {
        doNotCloseCollectionBox = false;
    });
    $('body').on('click', '#close-collections-box', function(e) {
        e.preventDefault();
        closeCollectionBox($(this).parents('#collections-box'));
    });
    $(document).click(function(event) {
        if ( ! doNotCloseCollectionBox && ! $(event.target).is('.fa-star, .fa-star-o')) {
            closeCollectionBox();
        }
    });
    $('.pagination .disabled a').click(function(event) {
        event.preventDefault();
    });
})
